<template>
  <div>
    <div
      class="flex bg-gray-100 items-center justify-center py-4 space-x-12 text-gray submenus md:space-x-0 md:justify-around"
    >
      <router-link :to="'/account/home'" class="subMenu">
        <font-awesome-icon :icon="['fas', 'user']" />
        我的帳號
      </router-link>

      <router-link :to="'/account/billing'" class="subMenu">
        <font-awesome-icon :icon="['fas', 'credit-card']" />
        購買紀錄</router-link
      >
      <router-link :to="'/account/history'" class="subMenu">
        <font-awesome-icon :icon="['fas', 'history']" />
        會員方案紀錄</router-link
      >

      <router-link :to="'/account/coupon'" class="subMenu">
        <font-awesome-icon :icon="['fas', 'ticket-alt']" />
        我的優惠券</router-link
      >
      <router-link :to="'/account/contact'" class="subMenu">
        <font-awesome-icon :icon="['fas', 'envelope']" />
        聯絡我們</router-link
      >
    </div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>
<script setup>
import { inject } from 'vue';
const version = inject('version');
</script>

<style lang="sass" scoped>
.submenus
  .subMenu
    @apply flex items-center hover:text-primary duration-300
    @screen md
      @apply text-sm
    svg
      @apply w-4 h-4 mr-2 #{!important}
  :deep(.router-link-active)
    @apply text-primary
</style>
